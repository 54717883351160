body {
  margin: 0;
  font-family: 'BentonSansPro_Regular';
  background-color: #F2F3F4 !important;
  min-width: 1200px;
}

@font-face {
  font-family: "BentonSansPro_Regular";
  src: url("./assets/fonts/BentonSansPro_Regular.woff") format("woff");
}

@font-face {
  font-family: "BentonSansPro_Medium";
  src: url("./assets/fonts/BentonSansPro_Medium.woff") format("woff");
}

@font-face {
  font-family: "BentonSansPro_Book";
  src: url("./assets/fonts/BentonSansPro_Bold.woff") format("woff");
}
